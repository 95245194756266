<template>
  <div id="quiz-create">
    <div id="quiz-title">
      <button id="btn-home" type="button" @click="onDashBoard" title="Quay về trang chủ">Home</button>
      <input type="text" id="quiz-title-text" placeholder="Nhập tiêu đề bài kiểm tra" v-model="quizData.title"
        required />
      <button id="btn-submit-quiz" type="button" @click="submitQuiz" title="Lưu bài kiểm tra">Hoàn thành</button>
    </div>
    <div id="container">
      <div id="question-navigation">
        <button id="btn-add-question" type="button" @click="addNewQuestion" title="Thêm câu hỏi mới">
          <img src="@/assets/plus-icon.svg" class="plus-icon" alt="Thêm mới" />
        </button>
        <button v-for="(question, index) in quizData.questions" :key="index" @click="loadQuestion(index)" :class="{
          'btn-question-navigation': true,
          highlighted: index === currentQuestionIndex,
        }">
          Câu hỏi {{ index + 1 }}
        </button>
      </div>

      <div id="question-container">
        <div ref="editorContainer" class="editor-container"></div>

        <div id="options-container" :class="quizData.questions[currentQuestionIndex].layout + '-layout'">
          <div v-for="(option, index) in quizData.questions[currentQuestionIndex].options" :key="index"
            class="div-option">
            <label class="checkbox-container">
              <input type="checkbox" v-model="option.hasCorrect" />
              <span class="checkmark" :data-text="String.fromCharCode(65 + index)"></span>
            </label>
            <input type="text" v-model="option.optionText" />
            <span v-if="showChoicesAndResults" class="answer-count">
              {{ answerCountsComputed[String.fromCharCode(65 + index)] }}
            </span>
          </div>
        </div>

        <div id="additional-container">
          <button id="btn-add-op" type="button" @click="addOption">Thêm lựa chọn</button>
          <button id="btn-del-question" type="button" @click="deleteQuestion" title="Xóa câu hỏi">Xóa</button>
        </div>
        <div id="layout-buttons">
          <button @click="setLayout('vertical')">Dọc</button>
          <button @click="setLayout('two-columns')">2 Cột</button>
          <button @click="setLayout('horizontal')">Ngang</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuizCreate from "./QuizCreate.js";

export default {
  name: "EditorComponent",
  data() {
    return {
      ...QuizCreate.data(),
      editor: null,
    };
  },

  mounted() {
    this.createEditorInstance();
  },

  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },

  methods: {
    ...QuizCreate.methods,
    saveData() {
      return this.editor.save();
    },
  },
};
</script>

<style src="./QuizCreate.css" scoped></style>
