import { db } from "@/firebase.js";

import { collection, addDoc, setDoc } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import FontSize from 'editorjs-inline-font-size-tool';
import YoutubeEmbed from 'editorjs-youtube-embed';
import Swal from 'sweetalert2';
import { apiServer } from '@/config'

export default {
    data() {
        return {
            editorData: { blocks: [] },
            currentQuestionIndex: 0,
            quizData: {
                title: '',
                questions: [
                    {
                        questionText: '',
                        options: [
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false }
                        ],
                        layout: 'vertical',
                    },
                    {
                        questionText: '',
                        options: [
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                        ],
                        layout: 'two-columns',
                    },
                ],
                lastEdited: null,
            },
            newOption: '',
        };
    },

    methods: {

        createEditorInstance() {
            this.editor = new EditorJS({
                holder: this.$refs.editorContainer,
                minHeight: 250,
                placeholder: 'Nhập nội dung. Bấm + để tùy chọn, bôi đen chữ để định dạng',
                tools: {
                    header: Header,
                    fontSize: FontSize,
                    image: {
                        class: ImageTool,
                        config: {
                            endpoints: {
                                byFile: apiServer + '/upload-image',
                            },
                            field: 'file',
                            displayOptions: ['upload'],
                        }
                    },
                    youtubeEmbed: YoutubeEmbed,
                },
            });

        },

        deleteOption(index) {
            this.quizData.questions[this.currentQuestionIndex].options.splice(index, 1);
        },

        addOption() {
            this.quizData.questions[this.currentQuestionIndex].options.push({
                hasCorrect: false
            });
            this.newOption = '';
        },

        saveCurrentQuestion() {

            // Lọc ra và chỉ giữ lại những phương án hợp lệ
            const validOptions = this.getValidOptions(this.quizData.questions[this.currentQuestionIndex]);
            this.quizData.questions[this.currentQuestionIndex].options = validOptions;

            return new Promise((resolve, reject) => {
                if (this.editor) {
                    this.editor.save()
                        .then(outputData => {
                            this.quizData.questions[this.currentQuestionIndex] = {
                                questionText: outputData, // Lưu trữ outputData thay vì chuyển đổi thành JSON
                                options: validOptions
                            };
                            resolve(); // Hoàn thành Promise khi lưu dữ liệu thành công
                        })
                        .catch(error => {
                            console.error('Lỗi khi lưu dữ liệu: ', error);
                            reject(error); // Từ chối Promise nếu có lỗi
                        });
                } else {
                    reject('Trình soạn thảo không khả dụng');
                }
            });
        },

        addNewQuestion() {
            // Kiểm tra nội dung câu hỏi hiện tại trước khi thêm mới
            this.checkValidEditorContent().then(isValid => {
                if (!isValid) {
                    Swal.fire({
                        title: 'Thông báo!',
                        text: 'Bạn chưa nhập nội dung câu hỏi hiện tại!',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                    return;
                }
            });

            // Kiểm tra các điều kiện khác
            if (!this.hasValidCorrectOption()) {
                Swal.fire({
                    title: 'Thông báo!',
                    text: 'Bạn chưa chọn đáp án đúng!',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            }

            if (this.validOptionsCount() < 2) {
                Swal.fire({
                    title: 'Thông báo!',
                    text: 'Câu hỏi cần ít nhất 2 phương án trả lời hợp lệ!',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            }

            // Lưu câu hỏi hiện tại trước khi thêm mới câu hỏi
            this.saveCurrentQuestion()
                .then(() => {
                    // Thêm câu hỏi mới với layout mặc định
                    const newQuestion = {
                        questionText: '',
                        options: [
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                            { optionText: '', hasCorrect: false },
                        ],
                        layout: 'vertical', // Bố cục mặc định là dọc
                    };

                    this.quizData.questions.push(newQuestion);
                    this.currentQuestionIndex = this.quizData.questions.length - 1;
                    this.editor.clear(); // Xóa nội dung editor
                })
                .catch(error => {
                    console.error('Lỗi khi lưu dữ liệu: ', error);
                });
        },


        deleteQuestion() {
            if (this.quizData.questions.length > 1) {
                Swal.fire({
                    title: 'Xác nhận!',
                    text: 'Bạn chắc chắn xóa câu hỏi hiện tại chứ?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Người dùng nhấp vào "OK"
                        this.quizData.questions.splice(this.currentQuestionIndex, 1);
                        this.currentQuestionIndex--;
                        const selectedQuestion = this.quizData.questions[this.currentQuestionIndex];
                        const blocks = selectedQuestion.questionText.blocks;
                        if (blocks && blocks.length > 0) {
                            this.editor.render({ blocks: blocks });
                        } else {
                            console.log('Lỗi');
                        }

                    } else if (result.isDismissed) {
                        // Người dùng nhấp vào "Cancel" hoặc nhấp ra ngoài thông báo
                        // Không làm gì cả hoặc thực hiện hành động khác nếu cần thiết
                    }
                });
            }
        },

        async loadQuestion(index) {
            // Lưu câu hỏi hiện tại trước khi load câu hỏi mới
            await this.saveCurrentQuestion();

            // Đặt chỉ số của câu hỏi hiện tại
            this.currentQuestionIndex = index;

            // Lấy dữ liệu từ câu hỏi đã lưu
            const selectedQuestion = this.quizData.questions[index];

            // Lấy các block dữ liệu từ selectedQuestion và hiển thị chúng trên EditorJS
            const blocks = selectedQuestion.questionText.blocks;
            console.log(blocks);
            if (blocks && blocks.length > 0) {
                this.editor.render({ blocks: blocks });
            } else {
                console.log('Lỗi');
            }
        },

        async submitQuiz() {

            // Kiểm tra tiêu đề bài kiểm tra
            if (!this.checkValidQuizTitle()) {
                Swal.fire({
                    title: 'Thông báo!',
                    text: 'Bạn chưa nhập tiêu đề bài kiểm tra!',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            }

            // Kiểm tra nội dung câu hỏi tiện tại
            this.checkValidEditorContent().then(isValid => {
                if (!isValid) {
                    Swal.fire({
                        title: 'Thông báo!',
                        text: 'Bạn chưa nhập nội dung câu hỏi hiện tại!',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                    return;
                }
            });

            // Hàm kiểm tra xem có ít nhất một phương án được chọn là đúng
            if (!this.hasValidCorrectOption()) {
                Swal.fire({
                    title: 'Thông báo!',
                    text: 'Bạn chưa chọn đáp án đúng!',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            }

            // Kiểm tra số lượng các phương án trả lời
            if (this.validOptionsCount() < 2) {
                Swal.fire({
                    title: 'Thông báo!',
                    text: 'Câu hỏi cần ít nhất 2 phương án trả lời hợp lệ!',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            } else {
                if (this.quizData.questions.length > 0) {
                    await this.saveCurrentQuestion();
                    this.saveQuizToFirestore(this.quizData);
                } else {
                    console.error('Ít nhất một câu hỏi là bắt buộc.');
                }
            }
        },

        onDashBoard() {
            this.$router.push('/user-dashboard')
        },

        async saveQuizToFirestore(quizData) {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;

                if (currentUser) {
                    quizData.ownerUID = currentUser.uid;
                    quizData.lastEdited = serverTimestamp();

                    const colRef = collection(db, 'quizzes');
                    const docRef = await addDoc(colRef, quizData);

                    const autoId = docRef.id;
                    quizData.id = autoId;
                    await setDoc(docRef, quizData);

                    console.log('Quiz đã được lưu thành công!');
                    this.$router.push('/manage-quizzes');
                } else {
                    console.error("Người dùng chưa đăng nhập.");
                }
            } catch (e) {
                console.error("Lỗi khi lưu bài kiểm tra lên Firebase: ", e);
            }
        },

        // Hàm kiểm tra tiêu đề bài kiểm tra
        checkValidQuizTitle() {
            return this.quizData.title.trim() !== "";
        },


        // Hàm kiểm tra nội dung câu hỏi
        checkValidEditorContent() {
            return new Promise((resolve, reject) => {
                if (this.editor) {
                    this.editor.save()
                        .then(outputData => {
                            if (outputData.blocks && outputData.blocks.length > 0) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch(error => {
                            console.error('Lỗi khi lưu dữ liệu: ', error);
                            resolve(false);
                            reject(error);
                        });
                } else {
                    resolve(false);
                }
            });
        },

        // Hàm trả về các phương án trả lời không rỗng
        getValidOptions(question) {
            return question.options.filter(option => option.optionText.trim() !== "");
        },

        // Hàm trả về số lượng các phương án trả lời có nội dung
        validOptionsCount() {
            return this.quizData.questions[this.currentQuestionIndex].options
                .filter(option => option.optionText.trim() !== "").length;
        },

        // Hàm kiểm tra xem có ít nhất một phương án được chọn là đúng
        hasValidCorrectOption() {
            const options = this.quizData.questions[this.currentQuestionIndex].options;
            return options.some(option => option.hasCorrect);
        },

        // Hàm cập nhật layout cho câu hỏi hiện tại
        setLayout(layout) {
            const currentQuestion = this.quizData.questions[this.currentQuestionIndex];
            if (currentQuestion) {
                currentQuestion.layout = layout;
            }
        },
    }
};
